import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Helmet} from "react-helmet";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {Link} from "react-router-dom";
import IntroComponent from './TrainingMaterial/Intro'
import HowToPromote from './TrainingMaterial/HowToPromote'
import ChapterTowComponent from './TrainingMaterial/ChapterTwoComponent'
import ChapterThreeCompent from './TrainingMaterial/ChapterThreeComponent'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function getTrainingValues(backendObj, key) {
  return key in backendObj ? backendObj[key] : false;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 'auto',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  buttons: {
    marginBottom: '40px'
  }
}));


export default function TrainingComponent(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const [intro, setIntro] = React.useState(false)
  const [chapterOne, setChapterOne] = React.useState(false)
  const [chapterTwo, setChapterTwo] = React.useState(false)
  const [chapterThree, setChapterThree] = React.useState(false)
  const [chapterComplete, setChapterComplete] = useState('')

  useEffect(() => {
    const { history, location, isLoggedIn } = props;

    const token = localStorage.getItem('token')
    if (!isLoggedIn) {
      history.push("/login", location);
    }
    let headers = { Authorization: `token ${token}` };
    props.getAgentProfile(headers)

  }, [])


  useEffect(() => {

    if (props.getAgentProfileSuccess && props.getAgentProfileSuccess.response.training_progress.level !== 'No Started') {
      let trainingProgress = JSON.parse(props.getAgentProfileSuccess.response.training_progress.level)

      setIntro(getTrainingValues(trainingProgress, 'intro'))
      setChapterOne(getTrainingValues(trainingProgress, 'chapter_1'))
      setChapterTwo(getTrainingValues(trainingProgress, 'chapter_2'))
      setChapterThree(getTrainingValues(trainingProgress, 'chapter_3'))
    }

  }, [props])


  useEffect(() => {

    if (props.updateTrainingProgressSuccess){
      let trainingProgress = JSON.parse(props.updateTrainingProgressSuccess.level)
      setIntro(getTrainingValues(trainingProgress, 'intro'))
      setChapterOne(getTrainingValues(trainingProgress, 'chapter_1'))
      setChapterTwo(getTrainingValues(trainingProgress, 'chapter_2'))
      setChapterThree(getTrainingValues(trainingProgress, 'chapter_3'))
    }
    }
    , [props.updateTrainingProgressSuccess]);

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
    mounted.current = true;
  } else {
    let updateChapter = { Chapter: `${chapterComplete}`}
    props.updateTrainingProgress(updateChapter)
  }


  }, [chapterComplete])


  const handleChange = (event, newValue) => {

    setValue(newValue);
  };

  const handleChapterComplete = (chapter) => {

    setChapterComplete(chapter)

  }

  const introEle = intro ? <div style={{textDecoration: 'line-through'}}>Intro</div> : <div>Intro</div>
  const chap1Ele = chapterOne ? <div style={{textDecoration: 'line-through'}}>Chapter 1</div> : <div>Chapter 1</div>
  const chap2Ele = chapterTwo ? <div style={{textDecoration: 'line-through'}}>Chapter 2</div> : <div>Chapter 2</div>
  const chap3Ele = chapterThree ? <div style={{textDecoration: 'line-through'}}>Chapter 3</div> : <div>Chapter 3</div>

  return (
    <div className="container cpanel">
      <Helmet>
        <title>{ "chicargos.com Supporter - Affiliate Programme - Promo Tools" }</title>
        <meta name="description" content="UK Premier import service - Promotional Tools" />
      </Helmet>
      <Breadcrumb>
        <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to="/profile">Account</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to="/supporter-dashboard">Dashboard</Link></BreadcrumbItem>
        <BreadcrumbItem active>Training</BreadcrumbItem>
      </Breadcrumb>
      <div style={{paddingBottom: '30px'}} className="page-header col-12">
        <h4 className="page-header-content">Supporter Training</h4>
        <hr className="fancy-line" />
        <br/>
      </div>

      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label={introEle} {...a11yProps(0)} />
          <Tab label={chap1Ele} {...a11yProps(1)} />
          <Tab label={chap2Ele} {...a11yProps(2)} />
          <Tab label={chap3Ele} {...a11yProps(3)} />

        </Tabs>
        <TabPanel value={value} index={0}>
          <IntroComponent/>
          <div className={classes.buttons}>
            <button
              onClick={() => handleChapterComplete("intro")}
              disabled={intro}
              className="download-button"
              >
              {intro ? 'Chapter Completed': 'Click to confirm chapter complete'}</button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HowToPromote/>
          <div className={classes.buttons}>
            <button
              onClick={() => handleChapterComplete("chapter_1")}
              disabled={chapterOne}
              className="download-button"
            >
              {chapterOne ? 'Chapter Completed': 'Click to confirm chapter complete'}</button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ChapterTowComponent/>
          <div className={classes.buttons}>
            <button
              onClick={() => handleChapterComplete("chapter_2")}
              disabled={chapterTwo}
              className="download-button"
            > {chapterTwo ? 'Chapter Completed': 'Click to confirm chapter complete'} </button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ChapterThreeCompent/>
          <button
            onClick={() => handleChapterComplete("chapter_3")}
            disabled={chapterThree}
            className="download-button"
          > {chapterThree ? 'Chapter Completed': 'Click to confirm chapter complete'} </button>
          <div style={{paddingBottom: '250px'}}>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

