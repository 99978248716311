import Tab from "react-bootstrap/Tab";
import {Col, Row} from "reactstrap";
import React from "react";
import {withRouter} from "react-router-dom";

const BlogBlueprint = (props) => {
  const coverImage = props.bImage
  const subHeading = props.subHeading
  const publishingDate = props.publishingDate
  return(
    <div style={{ textAlign: 'center' }}>
      <h4 className="page-header-content" style={{ color: '#b60811'}}>{props.title}</h4>
      <hr className="fancy-line" />
      <div className="col-lg-12 col-sm-12">
        <div style={{height: "10px", fontSize: "10px", color: "gray"}}>
          <i className="far fa-star" style={{fontSize:"10px", paddingRight: "10px"}}>  </i>
          {publishingDate}
        </div>
       <div style={{marginTop: "25px", height: '180px', backgroundImage: `url(${coverImage})`}} className="help-department-heading">
       </div>
      </div>
      <br />
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12} className="">
            <div style={{paddingTop: "5px"}}>
              <p className="how-to-steps" style={{fontSize: '18px', color: '#000000'}}>{subHeading}</p>

            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
}

export default withRouter(BlogBlueprint)
