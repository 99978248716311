import React, {useState, useEffect} from 'react';


function UserIpDetails() {
  const [country, setCountry] = useState('')
  const [number, setNumber] = useState('+447438 767532')

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then( res => res.json())
      .then(response => {
        if (response.country === 'ZM') {
          setNumber('+260 770462397')
        }
        setCountry(response.country)
      })
      .catch((data, status) => {
        // console.log('Request failed:', data);
        setCountry('GB')
      });
  },[])

  return (
    <div>
        {number}
    </div>
  );
}

export default UserIpDetails