import React, {Component} from 'react';
import BlogBlueprint from './../BlogBlueprintComponent'
import CoverImage from './../../assets/single-elements/blog-images/benz-cover.jpg'
import FeatureImage from './../../assets/single-elements/blog-images/car-history.jpg'
import {Helmet} from "react-helmet";



class WhyImportFromUK extends Component {

  render() {
    const title = "Why buy an Ex UK vehicle?"
    const bImage = CoverImage
    const subHeading = "The difference between ex UK and ex Japanese vehicles"
    const pubDate = "20 Sep - 5 min read"
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - Why buy an Ex UK vehicle?</title>
          <meta name="description"
                content="CHICARGOS Blog - A Guide on the things to consider when importing a vehicle from UK
                instead of Japan." />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="static-info-page static-info-padding" style={{paddingBottom: '60px'}}>
                  <div className="" style={{ textAlign: 'center' }}>
                    <BlogBlueprint
                      title={title}
                      bImage={bImage}
                      subHeading={subHeading}
                      publishingDate={pubDate}
                    >
                    </BlogBlueprint>
                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px', textAlign: 'left'}}>
                      As a motor vehicle shopper, we know that you may have your doubts about getting your vehicle
                      from the UK, ride with us as we digest the advantages of purchasing your vehicle from the UK.
                    </p>

                    <p className="page-information" style={{fontSize: '15px', paddingTop: '0px'}}>
                      <p  style={{textAlign: 'left'}}>
                        <ul>
                          <li style={{paddingBottom: '32px'}}>
                            <span style={{fontWeight: 700}}>Superior Specs and Quality:</span>
                            European cars usually have a high standard specification.
                            They are much more likely to have a leather interior whereas Japanese cars often
                            have cloth seats as standard. If you have a particular preference between a
                            diesel or petrol car, that can be one of the biggest driving factors to steer you to a
                            particular country. The UK is perfect for buying diesel 4x4s as majority of vehicles are
                            diesel. European cars are built with a stronger focus on fuel efficiency,
                            Diesel will give you greater fuel efficiency and the UK cars are typically higher
                            powered engines than the petrol equivalent.
                          </li>

                          <li style={{paddingBottom: '32px'}}>
                            <span style={{fontWeight: 700}}>Documentation and service history records:</span> One the beauties of importing a car is that the
                            information on some imports is much more extensive than the information available on
                            cars in Kenya. For example in the UK vehicles are regularly serviced and their service
                            records are regularly maintained. This means that when you buy a UK car you can be
                            confident the car has had its annual service so you can see it has been maintained properly.
                            HPI check will tell you if the mileage has been tampered with or if it’s been in
                            a bad accident. This simply means that a peace of mind is guaranteed for the buyer.
                            <br/>
                            <div style={{margin: "16px 0px 16px 0px", height: '180px', backgroundImage: `url(${FeatureImage})`}} className="help-department-heading">
                            </div>
                          </li>
                          <li style={{paddingBottom: '32px'}}>
                            <span style={{fontWeight: 700}}>Performance:</span> In terms of performance or power also, it is quite difficult to find an
                            alternate of European makers. Models of top European automakers such as BMW,
                            Mercedes-Benz and Audi are some of the best performing vehicles ever built.
                            The power and acceleration of these cars are much more than their counterparts.
                          </li>
                          <li style={{paddingBottom: '32px'}}>
                            <span style={{fontWeight: 700}}>Controls and Radio:</span> People who are buying higher end cars are more inclined to want to
                            buy from Europe because they know their cars will be ready to drive when they arrive.
                            The controls and radio will not need to be modified and so there is less to worry about and
                            less to interfere with.
                          </li>
                          <li style={{paddingBottom: '32px'}}>
                            <span style={{fontWeight: 700}}>Safety:</span> Euro NCAP is the European New Car Assessment Programme.
                            Its an organisation that tests the safety of cars you can buy in Europe by performing
                            a series of crash and safety tests on car models. The vehicles are then rated,
                            five stars indicate the highest safety rating and one star the lowest.
                            These tests show that vehicles sold in the EU tend to be a little more sturdy in their design and
                            build strength.
                          </li>
                        </ul>
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

}

export default WhyImportFromUK;