import Tab from "react-bootstrap/Tab";
import {Col, Row} from "reactstrap";
import React from "react";
import {withRouter} from "react-router-dom";

function renderParagraph (str) {
  return str.map((p) => (
    <p>{p}</p>
  ))
};


const BlogTemplate = (props) => {
  const coverImage = props.bImage
  const paragraph1 = props.paragraph1.split('|')
  const subHeading2 = props.subHeading2
  const paragraph2 = props.paragraph2.split('|')
  return(
    <div>
     <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>{props.title}</h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                    <div style={{height: "10px", fontSize: "10px", color: "gray"}}>
                      <i className="far fa-star" style={{fontSize:"10px", paddingRight: "10px"}}>  </i>
                      Nov 28 - 5 min read
                    </div>
                   <div style={{marginTop: "25px", backgroundImage: `url(${coverImage})`}} className="help-department-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          <p className="how-to-steps" style={{fontSize: '16px'}}>{props.subHeading}</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                              {renderParagraph(paragraph1)}
                            </p>

                          <p className="how-to-steps" style={{fontSize: '16px'}}>{subHeading2}</p>
                           <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                              {renderParagraph(paragraph2)}
                            </p>
                          <p className="how-to-steps" style={{fontSize: '16px'}}>Seamless buying process</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>
                              <p>
                              The first step is to take a long and satisfying look around the site to find the vehicle you want.
                              We make it easy to choose by providing clear and comprehensive details for every vehicle or product.
                              </p>
                              <p>
                              Once you're happy with your decision, you will proceed to fill out your shipping information.
                              Confirm your details by checking again from top to bottom before placing your order.
                              An order confirmation will be sent to your registered email with payment instructions
                              </p>
                              <p>
                              Contact your bank and make payment.
                              Our system is secure, and all details shared on chicargos.com are protected.
                              We offer two options for making payment to give you the utmost convenience - Wire Transfer or Bitcoin.

                              When you've made payment, contact CHICARGOS to confirm on +44 7438 767532.
                              There, you’ve done your part. Now you can leave the rest in the hands of our experts and wait for your purchase to arrive.
                              </p>
                              CHICARGOS will inform you of your dispatch date. We have taken measures to make sure this step is reliable as well. Your shipment will arrive as expected. And then again it’s over to you to enjoy your special purchase.
                              So you see, buying a car is easy with CHICARGOS. Don’t hesitate to get started on your dream purchase now. 
                            </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
    </div>
  )
}

export default withRouter(BlogTemplate)