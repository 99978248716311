import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, withRouter} from "react-router-dom";
// import AliceCarousel from 'react-alice-carousel';
// import "react-alice-carousel/lib/alice-carousel.css"
import NumberFormat from "react-number-format";
import ReactImageMagnify from 'react-image-magnify';
import { withCookies } from "react-cookie";
import { compose } from "redux";
import _ from 'lodash';
import {Helmet} from "react-helmet";
import { Event } from "../Tracking/Event"

// eslint-disable-next-line
import capitalize from "./../helpers/CapitalizeFirst"

import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardImg,
  Label,
  Button,
  TabContent, Nav, NavItem, NavLink, Alert, BreadcrumbItem, Breadcrumb
} from "reactstrap";
import onImage from "../../assets/images/no-image.jpg";
import credentials from "../../config/credentials"


import {Ports} from '../../shared/port_country';
import PromotionUpSelling from "../PromotionUpSelling";


class ProductDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.redirectAuthPage = this.redirectAuthPage.bind(this);
    this.redirectCheckout = this.redirectCheckout.bind(this);
    this.search = this.search.bind(this);
    this.buyNowRef = React.createRef();
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this)

    const { cookies } = props;
    this.state = {
      selectedIndex: 0,
      selectedValue: 'RORO',
      selectedPort: [],
      countries: [],
      ports: [],
      insurance: true,
      currSlide: 0,
      shippingCharge: 0,
      shippingCode: [],
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      vehicleID: "",
      message: "",
      destinationCountry: "Kenya",
      destinationPort: "Mombasa",
      url: "",
      shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }

  }

  componentDidUpdate(prevProps) {
    let { getVehicleDetail, addProductToBasketSuccess, addProductToBasketError, } = this.props;

    if (addProductToBasketError !== null && prevProps.addProductToBasketError !== addProductToBasketError) {
      if (addProductToBasketError === "AjaxError: ajax error 406") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "You have items awaiting checkout. Please proceed to checkout the outstanding items first or remove them from your basket"})
      } else if(addProductToBasketError === "AjaxError: ajax error 401") {
          this.props.history.push(`/login`);
      } else if(addProductToBasketError === "Item is unavailable") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "This product is out of stock "})
      } else {
        this.setState({message: addProductToBasketError})
      }
    }


    if (getVehicleDetail && prevProps.getVehicleDetail !== getVehicleDetail) {
      this.setState({ url: getVehicleDetail.url})
    } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
      this.props.history.push(`/my-basket`);
    }
  }

  responsive = {
      0: { items: 2 },
      300: { items: 3 },
      400: { items: 3 },
      500: { items: 4 },
      600: { items: 4 },
      700: { items: 4 },
      800: { items: 4 },
      1024: { items: 4 },
      2048: { items: 5 },
  }

  stagePadding = {
      paddingLeft: 0,
      paddingRight: 40,
  }

  body_type_suv = [
      'convertible','sports', '4x4', 'double cab','pick-up', 'touring', 'pickup', 'two door saloon gt shell', '3 door',
      'suv', 'stationwagon', 'low loader', 'pick up with canopy', 'four wheel drive', 'double cab pickup', 'mpv',
      'light 4x4 utility',

  ]

  body_type_sedan = [
      'four wheel drive hatchback', '5 door hatchback', 'sport back', 'hatch','2 door saloon', 'grand coupe', 'saloon',
      'hatchback', '5 door hatch', 'cabriolet // convertible', '4 door saloon', 'roadster','sedan', 'estate',


  ]

  body_type_commercial = [
      'car derived van','camper van','utility','beavertail / vehicle transporter', 'flatbed / hook loader',
      'kombi van', 'transporter', '3 door hatchback', 'beavertail / chassis cab / low loader', 'people carrier',
      'sportback', 'skip loader', 'chassis cab / luton', 'flatbed / tipper', 'panel', 'hook loader', 'box / panel van',
      'tilt & slide', 'dropside / tipper', 'box / catering', 'tipper', 'luton', 'cabin', 'beavertail / chassis cab',
      'beavertail / flatbed', 'panel van', 'box / high top / panel van', '7 seater', '5 seat window van', 'tipper blower',
      'van', 'vehicle transporter', 'hearse', 'box / high top','box / curtain side / low loader', 'crew van',
      'medium roof and medium wheel base', 'lwb 7 seater', 'chassis cab','flatbed', 'cherry picker / flatbed', 'limousine',
  ]

  body_types = [
      'other',
  ]


  componentDidMount() {
      document.body.scrollTop = 0
      window.scrollTo(0,0);

      const { getVehicle, getCountries, getPromotionalApiData, getShippingMethods } = this.props;
      let id = window.location.pathname.split('/')[2];
      getVehicle(id);
      getCountries();
      getPromotionalApiData();
      getShippingMethods();
      this.setState({vehicleID: id})
  };

  slideTo = (i) => this.setState({ currSlide: i });

  handleBreadCrumb(type, value) {

  let query = "1/0/0/1/*/*/0"

  if (type === "class") {
    query = `1/0/0/${value}/*/*/0`
  }
  if (type === "model") {
    query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/*/${this.state.sorted}`;
  }
  this.props.history.push(`/search/${query}`);
  }

  handleMakeChange(e) {
      this.setState({ [e.target.name]: e.target.value,  });
  }

  handlePorts = (e) => {
    this.setState({
      selectedPort: e.target.value,
      shippingCharge: 700
    }, () => {this.updateShippingCode()})
  }

  updateShippingCode = () => {
      const { getShippingMethodsSuccess } = this.props
      const shippingCodesFromMethods = getShippingMethodsSuccess.map(shippingObject => shippingObject.code)
      let veh_type = 'other'
      if (!(typeof this.state.selectedPort === 'string')) {
          return;
      }
      let selectedPort = this.state.selectedPort.split(" ").join("-").toLowerCase();
      let portMatches = _.filter(shippingCodesFromMethods, function (s) {
          return s.indexOf(selectedPort) !== -1;
      });
      if (this.props.getVehicleDetail.specs.body_type !== null ) {
          if (new RegExp(this.body_type_sedan.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'sedan'
          } else if (new RegExp(this.body_type_suv.join("|")).test(this.props.getVehicleDetail.specs.body_type.toLowerCase())) {
              veh_type = 'suv'
          }
      }

      let codeMatch = _.filter(portMatches, function (s) {
          return s.indexOf(veh_type) !== -1;
      });

      this.setState({
          shippingCode: codeMatch,
          message: ""
      }, () => {this.logIt()})
  }

  logIt()  {
  }

  handleCountryChange(e) {
    let Port = Ports.filter(x => x[e.target.value]);
    if(Port.length > 0)
      Port = Port[0][e.target.value]
    this.setState({ countries: e.target.value, ports : Port || []   });
  }

  handleRadios(val) {
    if (val === 'RORO'){
      this.setState({
        selectedIndex: 0, selectedValue: 'RORO', shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel" }, () => {this.logIt()})
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 700})
      }
    }
    else {
      this.setState({ selectedIndex: 1, selectedValue: 'Container', shippingOptionDetail: "Container: Your vehicle will be shipped in a container" })
      if (this.state.countries.length > 0) {
        this.setState({ shippingCharge: 1200})
        }
      }
  }

  handlePriceDisplay(event) {
    event.preventDefault();
    var { name } = event.target;
    if (event.target.tagName === "DIV") {
        name = 'radio1'
        var val = event.currentTarget.textContent
    }
    switch (name) {
      case 'country':
        this.handleCountryChange(event)
        break;

      case 'port':
        this.handlePorts(event)
        this.handleMakeChange(event)
        break;

      case 'radio1':
        this.handleRadios(val)
        break;

      default:
        break;
    }
  }

  handleBuyNow () {
    Event("PRODUCT", "Product added to cart", "PRODUCT_DETAIL_PAGE")
    if (this.props.isLoggedIn) {
      return <Button className='buyNow' size="sm" onClick={this.redirectCheckout}>
        Buy Now
      </Button>
    } else {
      return <Button className='buyNow' size="sm" onClick={this.redirectAuthPage}>
        Buy Now
      </Button>
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  redirectAuthPage () {
    this.props.history.push("/login")
  }

  search (nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i];
      }
    }
  }

  redirectCheckout () {
    const { destinationPort, url, shippingCode } = this.state
    const { getShippingMethodsSuccess } = this.props

    if (shippingCode.length === 0 || destinationPort === "") {
      this.setState({message: "Set Destination (Country & Port)"})
      return;
    } else {

      let optionsBaseURL = credentials.API_URL
      let returnedObject = this.search(shippingCode[0], getShippingMethodsSuccess)
      let optionURL = optionsBaseURL + returnedObject['description']
      let shippingValue = parseInt(returnedObject['price']['incl_tax'])

      this.props.addProductToBasket && this.props.addProductToBasket({ endpointAndQuantity: {url, quantity: 1, options: optionURL, value: shippingValue},
                                                                             sessionToken: this.props.allCookies });
    }
  }

  render() {
      const { getPriceSuccess, getVehicleDetailLoader, getVehicleDetail, getPromotionalApiDataSuccess,
        getCountriesSuccess, getStockRecordSuccess, addProductToBasketLoader } = this.props;

      const {
        ports,
        insurance,
        currSlide,
        message,
        shippingOptionDetail
      } = this.state;

      let shippingType = shippingOptionDetail.split(':')[0]
      let shippingTypeText = shippingOptionDetail.split(':')[1]

      let searched = [];
      let _images = [];
      let descr = [];
      let _specs = {manufacturer:null,model:null,mileage:null,model_year:null,engine_size:null,transmission:null,fuel_type:null,Petrol:null,body_type:null,Hatchback:null,doors:null,other:[] }
      if(getVehicleDetail) {
          const { images, description_clean,specs } = getVehicleDetail;
          _images = images;
          descr = description_clean.split(' - ');

          if(specs){
              _specs = specs
          }

      }
      if (getPromotionalApiDataSuccess) {
          const { most_searched: { sedan } } = getPromotionalApiDataSuccess;

          // TODO
          // logic to determine upselling category
          searched = [...sedan];
      }

      let availability = 0

      if (getStockRecordSuccess) {
        availability = getStockRecordSuccess.results[0].num_in_stock - getStockRecordSuccess.results[0].num_allocated
      }

      const pageTitle = getVehicleDetail && getVehicleDetail.title
      const fullTitle = "CHICARGOS: " + pageTitle
      const productClass = getVehicleDetail && getVehicleDetail.product_class
      const classValue = productClass === 'cars' ? '1' : '2'

      return (
        <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content="UK Premier exporter" />
          </Helmet>
          <div className="container text-center" id="contactContainer">
            <div className="row">

              {getVehicleDetailLoader ? (
                <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                  <Loading />
                </div>
              ) : (
                  <div className="container">
                      {/*<p className="text-left listedPTag">*/}
                      {/*  <Link to="/" className="listedLink">*/}
                      {/*    Home*/}
                      {/*  </Link>{" "}*/}
                      {/*>{" "}*/}
                      {/*<Link*/}
                      {/*    to="/"*/}
                      {/*    className="listedLink"*/}
                      {/*>*/}
                      {/*    Cars*/}
                      {/*  </Link>{" "}*/}
                      {/*  >{" "}*/}
                      {/*  <Link to="#" className="listedLink" onClick={this.goBack}>{ getVehicleDetail && getVehicleDetail.categories[0] }</Link>*/}
                      {/*</p>*/}
                    <Breadcrumb style={{marginBottom: "10px", borderBottom: "1px solid #DDD"}}>
                      <BreadcrumbItem>
                        <Link to="/" className="listedLink">
                          Home
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem>
                        <Link
                          to="#"
                          className="listedLink"
                          onClick={() => this.handleBreadCrumb("class", classValue)}
                        >
                            { getVehicleDetail && getVehicleDetail.product_class.capitalize() }
                          </Link>{" "}
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link
                            to="#"
                            className="listedLink"
                            onClick={() => this.handleBreadCrumb("model" )}
                        >
                            { getVehicleDetail && getVehicleDetail.categories[0] }
                          </Link>{" "}
                      </BreadcrumbItem>
                      <BreadcrumbItem active>
                        <Link to="#">
                          { getVehicleDetail && getVehicleDetail.title }
                        </Link>
                      </BreadcrumbItem>
                    </Breadcrumb>
                      <div>
                      <Row className="car-detail-layout">
                          <Col sm={12} md={5} lg={5} className="car-preview-gallery">
                              <div  style={{ marginTop: 10, height: "400px", cursor: 'zoom-in', border:"1px solid #e9ecef", position: 'relative' }}>
                                  {
                                      getVehicleDetail &&
                                      <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: 'car image',
                                            src: _images.length && getVehicleDetail ? _images[currSlide].remote_image : onImage,
                                            isFluidWidth: true,
                                            height:400,

                                        },
                                        largeImage: {
                                            src: _images.length && getVehicleDetail ? _images[currSlide].remote_image.replace("s-l600.jpg","s-l1600.jpg") : onImage,
                                            width: 1200,
                                            height: 1200
                                        },
                                        enlargedImageContainerDimensions: {
                                            width: '150%',
                                            height: '150%'
                                        },
                                        style:{maxHeight:"400px", left:"50%", top:"50%", transform:"translate(-50%, -50%)"},
                                        isHintEnabled: true,
                                        imageStyle:{objectFit:"contain",maxWidth:"100%",maxHeight:"400px"},
                                        // enlargedImageContainerStyle:{marginLeft:"60px"},
                                        enlargedImagePosition: 'over',
                                      }} />
                                  }
                              </div>

                          </Col>
                          <Col className="item-overview-detail" sm={12} md={7} lg={7}>
                              <p style={{ textAlign: 'left', fontWeight: 'bolder', fontSize: '20px', borderBottom: '1px solid rgba(0,0,0,0.2)', marginBottom: 0 }}>{ getVehicleDetail && getVehicleDetail.title }</p>
                              <p className='productCode'>{'Condition: Used'}</p>
                              <div id="wrapper" style={{ paddingBottom: '10px' }}>
                                  {/*<p className="detailPagePrice">$ { getPriceSuccess && getPriceSuccess.incl_tax }</p>*/}
                                  {/*$ { getPriceSuccess && parseFloat(getPriceSuccess.incl_tax) + this.state.shippingCharge }*/}
                                  <p className="detailPagePrice">
                                    <NumberFormat value={parseFloat(getPriceSuccess && getPriceSuccess.incl_tax) + this.state.shippingCharge} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'.00'} />
                                  </p>
                                  <p className="stockdata">Availability: <span className={"stock-" + (availability ? 'available': 'unavailable')}>
                                      { availability === 0 ? 'OUT OF STOCK': 'IN STOCK' }</span></p>
                                  <p className="stockdata" style={{ color: '#7d7d7d'}} >Item Code: { `#5${ getVehicleDetail && getVehicleDetail.id }` }</p>
                              </div>

                              { addProductToBasketLoader ? (
                                <div className="offset-1 offset-lg-1" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                                  <Loading />
                                </div>
                              ) : (
                              <div className='userOrderPreferences'>
                                <FormGroup className='formGroupRadios' style={{marginBottom: '0.01rem'}}>
                                    <Label className='radioLabelParent' for="deliveryOptions">Choose Your Delivery Options:</Label>
                                    <div className="radioContainer">
                                        {/*<RadioButton isChecked={this.state.selectedIndex === 0} text={'RORO'} value={'RORO'} index={0} handler={() => this.setState({ selectedIndex: 0, selectedValue: 'RORO' })} />*/}
                                        <RadioButton isChecked={this.state.selectedIndex === 0} text={'RORO'} value={'RORO'} index={0} handler={this.handlePriceDisplay.bind(this)} />
                                      {/*{getVehicleDetail && getVehicleDetail.product_class === 'cars' ? <RadioButton isChecked={this.state.selectedIndex === 1} text={'Container'} value={'Container'} index={1} handler={this.handlePriceDisplay.bind(this)} />*/}
                                      {/*: null}*/}

                                    </div>
                                </FormGroup>
                                  <div><p style={{ marginTop: '7px', fontSize: '13px', fontWeight: 500, textAlign: 'left' }}><span style={{color: '#980000'}}>{shippingType}:</span> {shippingTypeText}</p></div>
                                <div>
                                <FormGroup className="alert-set-destination">
                                  <div ref={this.buyNowRef}>
                                  {message && <Alert color="danger">
                                    {message}
                                  </Alert>}
                                  </div>
                                </FormGroup>
                                <FormGroup className="dropdownsContainer">
                                    <Input
                                        className='dropDowns'
                                        style={{ marginRight: '20px' }}
                                        onChange={e => this.handlePriceDisplay(e)}
                                        type="select"
                                        name="country"
                                        id="q">
                                        <option selected disabled>
                                            Select Destination Country
                                        </option>
                                        {
                                            getCountriesSuccess && getCountriesSuccess.results.map((country, ind) =>
                                                <option key={ind.toString()} value={country.name}>
                                                    { country.name }
                                                </option>
                                            )
                                        }
                                    </Input>
                                    <Input
                                        className='dropDowns'
                                        onChange={e => this.handlePriceDisplay(e)}
                                        type="select"
                                        name="port"
                                        id="m">
                                        <option selected disabled>
                                            Select Destination Port
                                        </option>
                                        {
                                            ports.map((port, ind) =>
                                                <option key={ind.toString()} value={port}>
                                                    { port }
                                                </option>
                                            )
                                        }
                                    </Input>
                                </FormGroup>
                                </div>
                                <div className='additionalContainer'>
                                    <p className='additional'>Additional Options:</p>
                                    <FormGroup style={{ display: 'flex' }} check>
                                        <Label className='containerCheckmark' check>
                                            <Input onChange={(e) => this.setState({ insurance: e.target.checked })} checked={insurance} type="checkbox" />
                                            <span className='checkmark'></span>
                                        </Label>
                                        <p style={{ marginTop: '7px', fontSize: '13px', fontWeight: 500 }}>Marine Insurance</p>
                                    </FormGroup>
                                    { this.handleBuyNow()}
                                </div>
                              </div>
                                )}

                          </Col>
                      </Row>

                      <div style={{ marginTop: 20, minHeight: 600 }}>
                          <Row>
                              <Col sm={12} md={12} lg={12}>

                                  <Nav tabs>
                                      <NavItem>
                                          <NavLink
                                              activeTab={1}
                                              active={true}
                                              style={{ fontWeight: 'bold' }}

                                          >
                                              About this vehicle
                                          </NavLink>
                                      </NavItem>
                                  </Nav>
                                      <TabContent activeTab style={{ paddingTop: '0px'}}>
                                          <Row>
                                              <Col style={{ marginTop: '10px', paddingBottom: '20px' }} sm="12">
                                              <div className='descriptionElement'>
                                                  <div style={{ width: '100%', height: '45px', background: '#E7E7E7', display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
                                                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Specification</div>
                                                  </div>

                                                 { customTable('Manufacturer', _specs.manufacturer) }
                                                 { customTable('Model', _specs.model) }
                                                  { customTable('Mileage', _specs.mileage) }
                                                  { customTable('Model Year', _specs.model_year) }
                                                  { customTable('Engine Size', _specs.engine_size) }
                                                  { customTable('Transmission', _specs.transmission) }
                                                  { customTable('Fuel Type', _specs.fuel_type) }
                                                  { customTable('Petrol', _specs.Petrol) }
                                                  { customTable('Body Type', _specs.body_type) }
                                                  { customTable('Hatchback', _specs.Hatchback) }
                                                  { customTable('Doors', _specs.doors) }

                                                  {_specs && _specs.other && _specs.other.map((spec,i) => {
                                                      return spec.Value  && customTable(spec.Name, spec.Value)
                                                  })}

                                              </div>

                                              <div className='descriptionElement'>
                                                  <div className='productDetailHeader'>
                                                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>Description</div>
                                                  </div>
                                                  <div className='descriptionContainer'>
                                                      { descr && descr.map((des) => <div className='decrText'>
                                                              {des}
                                                          </div>)
                                                      }
                                                  </div>

                                              </div>
                                              </Col>
                                          </Row>
                                      </TabContent>

                              </Col>
                          </Row>

                            <div className="container" style={{border: 'solid 1px #e4e4e4', marginTop: '40px',
                              marginBottom: '50px', paddingBottom: '20px'}}>
                              <div className="productDetailHeader" style={{background: 'white', fontWeight: 'bold', color: '#5a5a5a'}}>
                                People who viewed this item also viewed
                              </div>
                              <div className="row">
                               {searched && searched.map((d, i) => {
                                 return (
                                   <PromotionUpSelling d={d} i={i}>
                                   </PromotionUpSelling>
                                 );
                               })}
                              </div>
                            </div>
                          {/*</Row>*/}
                      </div>
                      </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  export default compose(
      withCookies,
      withRouter
  )(ProductDetailsComponent);



  const RadioButton = ({ isChecked, handler, value, text }) =>
          <div className="radio-btn-group" onClick={handler}>
              <div className={"radiobtn"} data-value={value} id="demo2">
                  <div className={isChecked ? "common checked" : "common unchecked"} data-value={value} id="demo"></div>
              </div>
              <label className='radioLabel'>{text}</label>
          </div>;

  const customTable = (text1, text2) =>
  {
      return text2 &&
      <div className='parent'>
          <div className='fixed'>
              {text1}
          </div>
          <div className='fluid'>
              {Array.isArray(text2) ? text2.join(", ") : text2 }
          </div>
      </div>
  };