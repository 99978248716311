console.log(process.env.REACT_APP_API_ENDPOINT)

console.log("=====================================================")
let credentials = {
  API_URL: "http://localhost/api",
  PROMOTIONAL_API_URL: "http://localhost/api/promotional/",
  PRODUCT_URL: "http://localhost/products",
  PRODUCT_DETAILS_URL: "http://localhost/api/products"
};

if(process.env.REACT_APP_API_ENDPOINT==="staging"){
  credentials = {
  API_URL: "http://35.178.143.248/api",
  PROMOTIONAL_API_URL: "http://35.178.143.248/api/promotional/",
  PRODUCT_URL: "http://35.178.143.248/products",
  PRODUCT_DETAILS_URL: "http://35.178.143.248/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="development"){
  credentials = {
  API_URL: "http://localhost:8000/api",
  PROMOTIONAL_API_URL: "http://localhost:8000/api/promotional/",
  PRODUCT_URL: "http://localhost:8000/products",
  PRODUCT_DETAILS_URL: "http://localhost:8000/api/products"
  }
}

if(process.env.REACT_APP_API_ENDPOINT==="prod"){
  credentials = {
  API_URL: "https://chicargos.com/api",
  PROMOTIONAL_API_URL: "https://chicargos.com/api/promotional/",
  PRODUCT_URL: "https://chicargos.com/products",
  PRODUCT_DETAILS_URL: "https://chicargos.com/api/products"
  }
}

export default credentials;
