import React, {useState, useEffect} from 'react';
import {Form, FormGroup, Input} from 'reactstrap';
import {charges} from '../../shared/ZRA_duties';
import {Col, Row} from "reactstrap";
import {Helmet} from "react-helmet";


function ZRADutyCalculator() {
  const [optionsOne, setOptionsOne] = useState([])
  const [optionsTwo, setOptionsTwo] = useState([])
  const [optionsThree, setOptionsThree] = useState([])
  const [optionsFour, setOptionsFour] = useState([])
  const [vehicleAge, setVehicleAge] = useState('')
  const [vehicleAgeFiltered, setVehicleAgeFiltered] = useState([])
  const [selectedFuel, setSelectedFuel] = useState('')
  const [selectedFuelFiltered, setSelectedFuelFiltered] = useState([])
  const [selectedVehicleType, setSelectedVehicleType] = useState('')
  const [selectedVehicleTypeFiltered, setSelectedVehicleTypeFiltered] = useState([])
  const [selectedDescription, setSelectedDescription] = useState('')
  const [duty, setDuty] = useState('')
  const [selectedDescriptionFiltered, setSelectedDescriptionFiltered] = useState([])

  useEffect(() => {
    const unique = [...new Set(charges.map(item => item.age))]
    setOptionsOne(unique)
  },[])

  const selectVehicleAge = optionsOne
    .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

  const selectFuelType = optionsTwo
    .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

  const selectVehicleType = optionsThree
    .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });

  const selectVehicleDescription = optionsFour
    .map((val, i) => {
      return (
        <option style={{ fontWeight: 700}} key={val + i} value={val}>
          {val}
        </option>
      );
    });


  const handleChange = (event) => {
    setVehicleAge(event.target.value);
    let newArray = charges.filter(function (el) {
      return el.age === event.target.value
    })
    setVehicleAgeFiltered(newArray)
    const uniqueFuel = [...new Set(newArray.map(item => item.fuel_type))]
    setOptionsTwo(uniqueFuel)
    setDuty('')
    setSelectedDescription('')
    setSelectedVehicleType('')
    setSelectedFuel('')
  };

  const handleFuelChange = (event) => {
    setSelectedFuel(event.target.value);
    let newArray = vehicleAgeFiltered.filter(function (el) {
      return el.fuel_type === event.target.value
    })
    setSelectedFuelFiltered(newArray)
    const uniqueVehicleType = [...new Set(newArray.map(item => item.vehicle_type))]
    setOptionsThree(uniqueVehicleType)
    setDuty('')
  }

  const handleVehicleTypeChange = (event) => {
    setSelectedVehicleType(event.target.value);
    let newArray = selectedFuelFiltered.filter(function (el) {
      return el.vehicle_type === event.target.value
    })
    setSelectedVehicleTypeFiltered(newArray)
    const uniqueDescription = [...new Set(newArray.map(item => item.description))]
    setOptionsFour(uniqueDescription)
    setDuty('')
  }

  const handleVehicleDescriptionChange = (event) => {
    setSelectedDescription(event.target.value);
    let newArray = selectedVehicleTypeFiltered.filter(function (el) {
      return el.description === event.target.value
    })
    setSelectedDescriptionFiltered(newArray)
    let dutyString = `Duty payable: K${newArray[0].duty}`
    setDuty(dutyString)
  }


  return (
    <div>
      <Helmet>
        <meta name="description"
              content="Find best import deals on Electronics, Mobile Phones, Games Console and much more." />
        <title>CHICARGOS - Electronics</title>
      </Helmet>
      <section className="gen-section  filter_space">
        <div className="container mobile_padding">
          <div className="row">
            <div className="col-lg-12 col-sm-12">

              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center', paddingBottom: '64px'}}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>ZRA Duty Calculator</h4>
                  <hr />
                  <br />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                    <Col sm={12}>
                      <Form
                        // onSubmit={this.handleSearch}
                        style={{ marginTop: '5px' }}
                      >
                        <div className="zra-select-headings">Select vehicle age</div>
                        <Input
                          placeholder="Select"
                          type="select"
                          name="select"
                          value={vehicleAge}
                          onChange={e => handleChange(e)}
                          id="exampleSelect"
                          className="zra-drop-downs"
                        >
                          <option value="">Select</option>
                          {selectVehicleAge}
                        </Input>
                        <div className="zra-select-headings">Select fuel type</div>
                        <Input
                          placeholder="Select"
                          type="select"
                          name="select"
                          value={selectedFuel}
                          onChange={e => handleFuelChange(e)}
                          id="fuelTypeSelect"
                          className="zra-drop-downs"
                        >
                          <option value="">Select</option>
                          {selectFuelType}
                        </Input>

                        <div className="zra-select-headings">Select vehicle type</div>
                        <Input
                          placeholder="Select"
                          type="select"
                          name="select"
                          value={selectedVehicleType}
                          onChange={e => handleVehicleTypeChange(e)}
                          id="fuelTypeSelect"
                          className="zra-drop-downs"
                        >
                          <option value="">Select</option>
                          {selectVehicleType}
                        </Input>

                        <div className="zra-select-headings">Select vehicle description</div>
                        <Input
                          placeholder="Select"
                          type="select"
                          name="select"
                          value={selectedDescription}
                          onChange={e => handleVehicleDescriptionChange(e)}
                          id="fuelTypeSelect"
                          className="zra-drop-downs"
                        >
                          <option value="">Select</option>
                          {selectVehicleDescription}
                        </Input>
                        <div style={{ paddingTop: '32px'}}>
                          <h5 className="page-header-content">{duty}</h5>
                        </div>
                      </Form>
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
}

export default ZRADutyCalculator
