import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { filterAction } from "../store/actions/filterCollapseAction";
import { authAction } from "../store/actions";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';



class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    const { cookies } = props;

    this.state = {
      isOpen: false,
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      redirect: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }
  }

  toggle() {
    this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    if (this.state.isOpen === true) {
      this.toggleNav();
    }
  }

  handleSignOut = async event => {
  const { cookies } = this.props;
  const token = localStorage.getItem('token')
  event.preventDefault();
  if (this.props.apiTokenLogout) {
    await this.props.apiTokenLogout({
      cookies,
      sessionToken: {
        token: token
      }
  });

  this.setState({
    userLoggedIn: false,
    email: "",
    redirect: true
  })
  this.props.filterCollapseAction(!this.props.filterReducer);
    this.setState({
      isOpen: !this.state.isOpen
    });
  this.props.history.push('/')
  }
  }


  render() {
    const { isLoggedIn } = this.props.authReducer

    // var location = window.location.pathname;
    return (
      <div className="header-section">
        <div className="header-container">
          <span/>
          <div className="header-left">
            {/*<span className="logo-left" style={{fontWeight: '500'}}>exUK</span>*/}
          </div>
          <div className="header-right">
            <Navbar dark expand="lg" id="" className="upper-nav">
              <Nav
                className="ml-auto"
                style={{ display: "inline-block" }}
                navbar>
                {/*<NavItem>*/}
                {/*  <a href="https://www.youtube.com/c/jamesqquick">*/}
                {/*    <img*/}
                {/*      src={require("../assets/single-elements/youtube-32.png")}*/}
                {/*      alt="social-icons"*/}
                {/*      className="social-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*  <a href="https://facebook.com/">*/}
                {/*    <img*/}
                {/*      src={require("../assets/single-elements/facebook-32.png")}*/}
                {/*      alt="social-icons"*/}
                {/*      className="social-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*  <a href="https://twitter.com/login?lang=e">*/}
                {/*    <img*/}
                {/*      src={require("../assets/single-elements/twitter-32.png")}*/}
                {/*      alt="social-icons"*/}
                {/*      className="social-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</NavItem>*/}
                {/*<NavItem style={{ padding: "0px" }}>*/}
                {/*  <a href="https://www.instagram.com/">*/}
                {/*    <img*/}
                {/*      className="social-icons"*/}
                {/*      src={require("../assets/single-elements/instagram.png")}*/}
                {/*      alt="social-icons"*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</NavItem>*/}
              </Nav>
            </Navbar>
            <Navbar dark expand="lg" id="navbar-first-row">
              <NavbarBrand href="/" className="mr-auto" style={{paddingLeft: '5px'}}>
                <span className="logo-right">CHICARGOS</span>
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Nav className="ml-auto" id="navbar-nav" navbar>
                <NavItem>
                  <Link
                  to="/my-basket">
                   <img
                    src={require("../assets/single-elements/cart-73-32.png")}
                    className="cart"
                    alt="cart"
                  />
                  </Link>

                </NavItem>
                <div>&nbsp;</div>
                <NavItem>
                  {!isLoggedIn && (
                    <Link
                      to="/login"
                      onClick={this.closeNavbar}
                      className="fancy">
                      <span className="fancy-header-items"> Hello, Sign in </span>
                    </Link>
                  )}
                  {isLoggedIn && (
                    <Link
                    to="/"
                    onClick={this.handleSignOut}
                    className="fancy">
                    <span className="fancy-header-items"> Hi, Sign Out</span>
                    </Link>
                  )}
                </NavItem>
                <div>&nbsp;</div>
                <NavItem className="my-account">
                  <Link
                    to="/profile"
                    className="fancy fancy-icons">
                    <ManageAccountsOutlinedIcon/>
                    {/*<span className="fancy-header-items"> My Account</span>*/}
                  </Link>{" "}
                  {/*<img*/}
                  {/*  className="my-account-image"*/}
                  {/*  style={{ width: "15px" }}*/}
                  {/*  src={require("../assets/single-elements/user-32.png")}*/}
                  {/*  alt="icons"*/}
                  {/*/>*/}
                </NavItem>
                <NavItem >
                  <Link
                    to="/my-basket"
                    className="fancy fancy-icons">
                    <ShoppingCartOutlinedIcon/>
                  </Link>
                </NavItem>
              </Nav>
            </Navbar>
          </div>
        </div>

        <div
          className="nav-container">
          {/*style={this.props.sideMenuReducer ? { display: "none" } : null}*/}
          {/*style={location !== '/' ? { display: "none" } : null}>*/}
          <Navbar dark expand="lg" id="navbar-second-row">
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="" navbar>

                <Link
                  to="/"
                  // style={{marginLeft: '0px !important'}}
                  className="mr-3-first-link"
                  onClick={this.closeNavbar}>
                  {/*{" "}*/}
                  <NavItem className="first-nav-item">
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-home-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Home
                </Link>


                <Link
                  to="/partner-one-search-cat/cat-search/0/75000/1/b/cars"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-car-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Cars
                </Link>

                <Link
                  to="/partner-one-search-cat/cat-search/0/75000/1/b/com"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-truck-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Commercial
                </Link>

                <Link
                  to="/electronics"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-mobile-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Electronics
                </Link>

                <Link
                  to="/auto-parts"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-engine-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Parts
                </Link>

                <Link
                  to="/help-contact"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      src={require("../assets/single-elements/icons8-about-26.png")}
                      className="nav-icon"
                      alt="icons"
                    />
                  </NavItem>
                  Help
                </Link>

                <Link
                  to="/local-services"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                      className="nav-icon"
                      src={require("../assets/single-elements/icons8-geography-26.png")}
                      alt="icons"
                    />
                  </NavItem>
                  Local services
                </Link>

                <Link
                  to="/about-us"
                  className="mr-3"
                  onClick={this.closeNavbar}>
                  <NavItem>
                    <img
                    className="nav-icon"
                    src={require("../assets/single-elements/icons8-about-26.png")}
                    alt="icons"
                    />
                  </NavItem>
                  About us
                </Link>

                {/*<NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">*/}
                {/*  {!isLoggedIn && (*/}
                {/*    <Link*/}
                {/*      to="/login"*/}
                {/*      onClick={this.closeNavbar}*/}
                {/*      >*/}
                {/*      Sign in / Register*/}
                {/*    </Link>*/}
                {/*  )}*/}
                {/*  {isLoggedIn && (*/}
                {/*    <Link*/}
                {/*      to="/"*/}
                {/*      onClick={this.handleSignOut}*/}
                {/*      >*/}
                {/*      Sign Out*/}
                {/*    </Link>*/}
                {/*  )}*/}
                {/*</NavItem>*/}

                {!isLoggedIn && (
                <Link
                  to="/login"
                  onClick={this.closeNavbar}
                  >
                  <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
                    Sign in
                  </NavItem>
                </Link>
                )}
                {isLoggedIn && (
                <Link
                  to="/"
                  onClick={this.handleSignOut}
                  >
                  <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
                    Sign Out
                  </NavItem>
                </Link>
                )}

                <Link
                  to="/profile"
                  onClick={this.closeNavbar}
                >
                  <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
                    My Account
                  </NavItem>
                </Link>

                <Link
                  to="/my-basket"
                  onClick={this.closeNavbar}
                >
                  <NavItem className="d-block d-sm-block d-md-none d-lg-none hiddenNavItems">
                  Cart
                  </NavItem>
                </Link>


              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filterReducer: state.filterReducer,
  sideMenuReducer: state.sideMenuReducer,
  authReducer: state.authReducer,

});

const mapDispatchToProps = dispatch => {
  return {
    filterCollapseAction: payload =>
      dispatch(filterAction.setFilterCollapse(payload)),
    apiTokenLogout: payload =>
      dispatch(authAction.apiTokenLogout(payload))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(
    withCookies,
    withRouter
)(Header));

