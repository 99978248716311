import React, { Component } from "react";
import { Loading } from "../LoadingComponent";
import {Link, withRouter} from "react-router-dom";
import NumberFormat from "react-number-format";
import { withCookies } from "react-cookie";
import { compose } from "redux";
import {Helmet} from "react-helmet";
import { Event } from "../Tracking/Event"
import { countries } from "../../shared/serviced_countries";
import QuantityPicker from "../QuantityPicker"

// eslint-disable-next-line
import capitalize from "./../helpers/CapitalizeFirst"

import {
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  TabContent, Nav, NavItem, NavLink, Alert, BreadcrumbItem, Breadcrumb
} from "reactstrap";
import credentials from "../../config/credentials"


import PromotionUpSelling from "../PromotionUpSelling";
import ItemImageCarousel from "./ItemImageCarousel";
import AlertDialog from "./AlertDialog";


class ItemDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.redirectAuthPage = this.redirectAuthPage.bind(this);
    this.redirectCheckout = this.redirectCheckout.bind(this);
    this.search = this.search.bind(this);
    this.buyNowRef = React.createRef();
    this.handleBreadCrumb = this.handleBreadCrumb.bind(this);
    this.quantityHandler = this.quantityHandler.bind(this);
    this.getMeta = this.getMeta.bind(this);
    this.handleInquire = this.handleInquire.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

    const { cookies } = props;
    this.state = {
      selectedIndex: 0,
      selectedValue: 'RORO',
      selectedPort: [],
      countries: [],
      ports: [],
      insurance: true,
      quantity: 1,
      currSlide: 0,
      shippingCharge: 0,
      shippingCode: [],
      email: cookies.get("email") || "",
      userLoggedIn: !!cookies.get("email"),
      vehicleID: "",
      itemID: "",
      message: "",
      country: "",
      destinationCountry: "Kenya",
      destinationPort: "Mombasa",
      url: "",
      price: "",
      shippingOptionDetail: "RORO: Your vehicle will Roll ON / Roll OFF ship vessel",
      openDialog: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.email !== this.state.email) {
      const email = nextProps.cookies.get("email");
      this.setState({
        email: email || "",
        userLoggedIn: !!email
      });
    }

  }

  componentDidUpdate(prevProps) {
    let { getItemDetail, addProductToBasketSuccess, addProductToBasketError, } = this.props;

    if (addProductToBasketError !== null && prevProps.addProductToBasketError !== addProductToBasketError) {
      if (addProductToBasketError === "AjaxError: ajax error 406") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "You have items awaiting checkout. Please proceed to checkout the outstanding items first or remove them from your basket"})
      } else if(addProductToBasketError === "AjaxError: ajax error 401") {
          this.props.history.push(`/login`);
      } else if(addProductToBasketError === "Item is unavailable") {
        this.buyNowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        this.setState({message: "This product is out of stock "})
      } else {
        this.setState({message: addProductToBasketError})
      }
    }

    if (getItemDetail && prevProps.getItemDetail !== getItemDetail) {
      this.setState({ url: getItemDetail.url, itemID: getItemDetail.id })
    } else if (addProductToBasketSuccess && prevProps.addProductToBasketSuccess !== addProductToBasketSuccess) {
      this.props.history.push(`/my-basket`);
    }
  }

  responsive = {
      0: { items: 2 },
      300: { items: 3 },
      400: { items: 3 },
      500: { items: 4 },
      600: { items: 4 },
      700: { items: 4 },
      800: { items: 4 },
      1024: { items: 4 },
      2048: { items: 5 },
  }

  stagePadding = {
      paddingLeft: 0,
      paddingRight: 40,
  }



  componentDidMount() {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
      const { getItem, getPromotionalApiData} = this.props;
      let id = window.location.pathname.split('/')[2];
      let price = window.location.pathname.split('/')[3]
      getItem(id);
      getPromotionalApiData();
      this.setState({vehicleID: id, price: price})
  };

  slideTo = (i) => this.setState({ currSlide: i });

  handleBreadCrumb(type, value) {

  let query = "1/0/0/1/*/*/0"

  if (type === "class") {
    query = `1/0/0/${value}/*/*/0`
  }
  if (type === "model") {
    query = `1/0/0/${this.props.url.st}/${this.props.url.sv}/*/${this.state.sorted}`;
  }
  this.props.history.push(`/search/${query}`);
  }

  handleMakeChange(e) {
      this.setState({ [e.target.name]: e.target.value,  });
  }


  handleCountryChange(e) {
    this.setState({ [e.target.name]: e.target.value  });
  }

  closeDialog = () => {
    this.setState({
      openDialog: false
    })
  };

  handleInquire(e) {
    this.setState({
      openDialog: true
    })
  }


  handlePriceDisplay(event) {
    event.preventDefault();

    var { name } = event.target;
    if (event.target.tagName === "DIV") {
        name = 'radio1'
    }
    switch (name) {
      case 'country':
        this.handleCountryChange(event)
        break;

      default:
        break;
    }
  }

  handleBuyNow (listingType) {
    Event("PRODUCT", "Product added to cart", "PRODUCT_DETAIL_PAGE")

    if (listingType === 'Chinese') {
      return <div className="align-button-left">
        <Button className="detail-page-inquire-btn" size="md" onClick={(e) => this.handleInquire(e)}>
          *Inquire
        </Button>
        <p className="decrText" style={{fontSize: '0.75rem', marginTop: '10px', marginBottom: '0px', paddingLeft: '3px'}}>
          *This item is under auction</p>
        </div>

    }
    if (this.props.isLoggedIn) {
      return<div className="align-button-left">
        <Button className='buyNow' size="sm" onClick={this.redirectCheckout}>
          Buy Now
        </Button>
      </div>
    } else {
      return <div className="align-button-left">
        <Button className='buyNow' size="sm" onClick={this.redirectAuthPage}>
          Buy Now
        </Button>
      </div>
    }
  }

  goBack = () => {
    this.props.history.goBack();
  }

  getMeta(url){
    console.log(url)
    var img = new Image();
    img.addEventListener("load", function(){
        alert( this.naturalWidth +' '+ this.naturalHeight );
    });
    img.src = url;
    return [parseInt(this.naturalWidth), parseInt(this.naturalHeight)]
  }

  redirectAuthPage () {
    this.props.history.push("/login")
  }

  search (nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
      if (myArray[i].code === nameKey) {
        return myArray[i];
      }
    }
  }

  quantityHandler(value) {
    this.setState({
      quantity: value
    })
  }

  redirectCheckout () {
    const { country, itemID, quantity } = this.state
    const { getShippingMethodsSuccess } = this.props
    if (country === "") {
      this.setState({message: "Set Destination (Country)"})
      return;
    } else {
      let optionsBaseURL = credentials.API_URL
      let country_code = country.toLowerCase().replace(" ", "-") + '-dest'
      let returnedObject = this.search(country_code, getShippingMethodsSuccess)
      let optionURL = optionsBaseURL + returnedObject['description']
      let url = optionsBaseURL + "/products/" + itemID + "/"
      this.props.addProductToBasket && this.props.addProductToBasket({ endpointAndQuantity: {url, quantity: quantity, options: optionURL, value: '0'},
                                                                             sessionToken: this.props.allCookies });

    }
  }

  render() {
      const { getItemDetailLoader, getItemDetail, getPromotionalApiDataSuccess,
         addProductToBasketLoader } = this.props;

      const all_countries = countries.map((val, i) => {
        return (
          <option className="options-text" key={i} value={val}>
            {val}
          </option>
        )
      })

      const {
        currSlide,
        message,
      } = this.state;

      let searched = [];
      let _images = [];
      let descr = [];
      let listingType = "";
      let _specs = {manufacturer:null,model:null,mileage:null,model_year:null,engine_size:null,transmission:null,fuel_type:null,Petrol:null,body_type:null,Hatchback:null,doors:null,other:[] }
      if(getItemDetail) {
        const { images, description_clean,specs, listing_type } = getItemDetail;
        _images = images;
        descr = description_clean.split(' - ');
        listingType = listing_type

        if(specs){
            _specs = specs
        }
      }
      if (getPromotionalApiDataSuccess) {
        const { most_searched: { sedan } } = getPromotionalApiDataSuccess;
        // TODO
        // logic to determine upselling category
        searched = [...sedan];
      }
      let imageDimensions = [800, 800]

      let availability = 1

      const pageTitle = getItemDetail && getItemDetail.title
      const fullTitle = "CHICARGOS: " + pageTitle
      const productClass = getItemDetail && getItemDetail.product_class
      const maxQuantity = getItemDetail && getItemDetail.quantity
      const itemCondition = getItemDetail && getItemDetail.condition
      const itemOrigin = getItemDetail && getItemDetail.item_origin
      const itemPrice = this.state.price
      const classValue = productClass === 'cars' ? '1' : '2'

      return (
        <div ref={topElement => (this.topElement = topElement)}>
          <Helmet>
            <title>{ fullTitle }</title>
            <meta name="description" content="UK Premier exporter" />
          </Helmet>
          <div className="container text-center" id="contactContainer">
            <div className="row">
              {getItemDetailLoader ? (
                <div className="offset-5 offset-lg-6" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                  <Loading />
                </div>
              ) : (
                <div className="container">
                  <Breadcrumb className="breadcrumb-container"
                              style={{marginBottom: "10px", borderBottom: "1px solid #f3f3f3"}}>
                    <BreadcrumbItem>
                      <Link to="/" className="listedLink">
                        Home
                      </Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link
                        to="#"
                        className="listedLink"
                        onClick={() => this.handleBreadCrumb("class", classValue)}
                      >
                          { getItemDetail && getItemDetail.product_class.capitalize() }
                        </Link>{" "}
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      <Link to="#">
                        { getItemDetail && getItemDetail.title }
                      </Link>
                    </BreadcrumbItem>
                  </Breadcrumb>
                  <AlertDialog
                    display={this.state.openDialog}
                    onClose={this.closeDialog}
                    heading={<span style={{color: "red", fontWeight: "bolder"}}>Auctioned Item</span>}
                    msg={<span>
                      This item is under auction. <br/>
                      Send us a whatsapp message on
                      <span style={{fontWeight: "bolder"}}>
                        <a href="tel:+447438 767532"> +447438 767532 </a>
                      </span>
                      to find out more about bidding on auctioned items.
                    </span>}
                  />
                  <div>
                    <Row className="car-detail-layout">
                      <Col sm={12} md={5} lg={5} className="car-preview-gallery">
                        <div className="prod-img-carousel">
                          <ItemImageCarousel images={_images}/>
                        </div>
                      </Col>
                      <Col className="item-overview-detail" sm={12} md={7} lg={7}>
                        <p className="product-title">{ getItemDetail && getItemDetail.title }</p>
                        <p className='productCode'>
                          Condition: {itemCondition}
                        </p>
                        <p className="productCode">
                          Postage: {itemOrigin === "GB" ? "5-7 days": "7-10 days" }
                        </p>
                        <div id="wrapper" style={{ paddingBottom: '10px' }}>
                          <p className="detailPagePrice">
                            <NumberFormat value={itemPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} suffix={'.00'} />
                          </p>
                          <p className="stockdata">Availability: <span className={"stock-" + (availability ? 'available': 'unavailable')}>
                              { availability === 0 ? 'OUT OF STOCK': 'IN STOCK' }</span></p>
                          <p className="stockdata" style={{ color: '#7d7d7d'}} >Item Code: { `#5${ getItemDetail && getItemDetail.id }` }</p>
                        </div>
                        { addProductToBasketLoader ? (
                          <div className="offset-1 offset-lg-1" style={{paddingTop: '100px', paddingBottom: '1220px'}}>
                            <Loading />
                          </div>
                        ) : (
                        <div className='userOrderPreferences'>
                          <div>
                          <FormGroup className="alert-set-destination">
                            <div ref={this.buyNowRef}>
                            {message && <Alert color="danger">
                              {message}
                            </Alert>}
                            </div>
                          </FormGroup>
                          <FormGroup className="dropdownsContainer">
                            <Input
                              className='dropDowns'
                              style={{ marginRight: '20px' }}
                              onChange={e => this.handlePriceDisplay(e)}
                              type="select"
                              name="country"
                              id="q">
                              <option className="options-text" selected disabled>
                                Select Destination Country
                              </option>
                              {all_countries}
                            </Input>
                          </FormGroup>
                          </div>
                          <div className='additionalContainer'>
                            <p className='additional'>Quantity:</p>
                            <div style={{marginBottom: '1rem'}}>
                                <QuantityPicker min={1} max={maxQuantity} handler={this.quantityHandler} />
                            </div>
                            {/*</FormGroup>*/}
                            { this.handleBuyNow(listingType)}
                          </div>
                        </div>
                          )}
                      </Col>
                    </Row>

                    <div style={{ marginTop: 20, minHeight: 600 }}>
                      <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                activeTab={1}
                                active={true}
                                style={{ fontWeight: 'bold' }}>
                                  About this item
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab style={{ paddingTop: '0px'}}>
                            <Row>
                              <Col style={{ marginTop: '10px', paddingBottom: '20px' }} sm="12">
                                <div className='descriptionElement'>
                                  <div className="specification-container">
                                    <div style={{ fontWeight: "bold", fontSize: "16px", color: "white" }}>
                                      Specification
                                    </div>
                                  </div>
                                    { customTable('Manufacturer', _specs.manufacturer) }
                                    { customTable('Model', _specs.model) }
                                    { customTable('Mileage', _specs.mileage) }
                                    { customTable('Model Year', _specs.model_year) }
                                    { customTable('Engine Size', _specs.engine_size) }
                                    { customTable('Transmission', _specs.transmission) }
                                    { customTable('Fuel Type', _specs.fuel_type) }
                                    { customTable('Petrol', _specs.Petrol) }
                                    { customTable('Body Type', _specs.body_type) }
                                    { customTable('Hatchback', _specs.Hatchback) }
                                    { customTable('Doors', _specs.doors) }

                                    {_specs && _specs.other && _specs.other.map((spec,i) => {
                                        return spec.Value  && customTable(spec.Name, spec.Value)
                                    })}
                                </div>

                                <div className='descriptionElement'>
                                  <div className='productDetailHeader'>
                                    <div style={{ fontWeight: 'bold', fontSize: '16px' }}>Description</div>
                                  </div>
                                  <div className='descriptionContainer'>
                                    { descr && descr.map((des) =>
                                      <div className='decrText'>
                                        {des}
                                      </div>)
                                    }
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </TabContent>
                        </Col>
                      </Row>
                      <div className="container" style={{border: 'solid 1px #e4e4e4', marginTop: '40px',
                          marginBottom: '50px', paddingBottom: '20px'}}>
                        <div className="productDetailHeader">
                          People who viewed this item also viewed
                        </div>
                        <div className="row">
                         {searched && searched.map((d, i) => {
                           return (
                             <PromotionUpSelling d={d} i={i}>
                             </PromotionUpSelling>
                           );
                         })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  export default compose(
      withCookies,
      withRouter
  )(ItemDetailsComponent);

  const customTable = (text1, text2) =>
  {
    if (text1 === 'Reg. Mark') {
      return text2 &&
      <div className='parent'>
          <div className='fixed'>
              {text1}
          </div>
          <div className='fluid'>
              None
          </div>
      </div>
    }
      return text2 &&
      <div className='parent'>
          <div className='fixed'>
              {text1}
          </div>
          <div className='fluid'>
              {Array.isArray(text2) ? text2.join(", ") : text2 }
          </div>
      </div>
  };