import React, { Component } from 'react';
import {
  Button, Col, Row
} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import { Loading } from './LoadingComponent';
import './BestDeals.css';
// import 'react-alice-carousel/lib/alice-carousel.css';
import ShopByMake from './../containers/ShopByMakeContainer';
import PromotionUpSelling from "./PromotionUpSelling";
import PromotionUpSellingStatic from "./PromotionalUpSellingStatic"
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import LazyLoad from 'react-lazyload';
import {dev_landing_data, homeTechData, homeCommercialData} from "../shared/promo_landing_data"
import PromoElements from "./PromoElements";
import insta from "../assets/single-elements/icons8-instagram-chicargos.jpg"
import fb from "../assets/single-elements/icons8-facebook-chicargos.jpg"
import twitter from "../assets/single-elements/icons8-twitter-squared-chicargos.jpg"


class BestDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      isLoading: true,
      errMess: null,
      dealers: [],
      recommended: [],
      parts: [],
      selectedPriceTab: 1,
      selectedSearchedCarTab: 1
    };
  }

  componentDidMount() {
    this.props.getPromotionalApiData && this.props.getPromotionalApiData();

    if (this.props && this.props.location.state && this.props.location.state.pathname && this.props.location.state.pathname === "/login") {
      document.body.scrollTop = 0
      window.scrollTo(0,0);
    }
  }


  render() {
    let {
      getPromotionalApiDataSuccess,
      getPromotionalApiDataError,
      getPromotionalApiDataLoader,
    } = this.props;

    let { selectedPriceTab, selectedSearchedCarTab } = this.state;
    let trustedCars = [];
    let mostSearched = [];
    let commercialVehicles = [];

    if (getPromotionalApiDataSuccess) {
      const {
        trusted_cars,
        most_searched,
        commercial_vehicles,
      } = getPromotionalApiDataSuccess;

      if (selectedPriceTab === 0) {
        trustedCars = trusted_cars.low_range;
      } else if (selectedPriceTab === 1) {
        trustedCars = trusted_cars.mid_range;
      } else if (selectedPriceTab === 2) {
        trustedCars = trusted_cars.upper_range;
      }

      if (selectedSearchedCarTab === 0) {
        mostSearched = most_searched.hatch_back;
      } else if (selectedSearchedCarTab === 1) {
        mostSearched = most_searched.sedan;
      } else if (selectedSearchedCarTab === 2) {
        mostSearched = most_searched.suv;
      }

      commercialVehicles = commercial_vehicles;

    }

    const staticAutoParts = [
      {remote_image: require('../assets/single-elements/car-parts/eukonline_service_kit.png'),
      title: "SERVICE KITS",
      price: 20,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-kits'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_tyres.jpg"),
      title: "TYRES",
      price: 25,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-tyres'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_engines.jpg"),
      title: "ENGINES",
      price: 150,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-engines'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_tail_light.jpg"),
      title: "AUTO BODY PARTS",
      price: 10,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-exterior'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_sensors.jpg"),
      title: "SENSORS",
      price: 15,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-sensors'
      },
      {remote_image: require("../assets/single-elements/car-parts/exukonline_entertainment_systems.jpg"),
      title: "ENTERTAINMENT",
      price: 80,
      id: '/partner-one-search-cat/cat-search/0/75000/1/b/parts-ent'
}
    ]



    if (getPromotionalApiDataLoader) {
      return (
        <div>
          <div className="headings-div text-center text-white ">
            <h5 className="headings">Best Deals</h5>
          </div>
          <Loading />
          <div className="headings-div text-center text-white  mt-4">
            <h5 className="headings">Recommended For You</h5>
          </div>
          <Loading />
          <div className="headings-div text-center text-white  mt-4">
            <h5 className="headings">Auto Parts</h5>
          </div>
          <Loading />
          <div className="headings-div text-center text-white  mt-4">
            <h5 className="headings">Featured Sales &amp; Events</h5>
          </div>
          <Loading />
        </div>
      );
    } else if (
      getPromotionalApiDataError &&
      getPromotionalApiDataError.errMess
    ) {
      return (
        <div>
          <div className="headings-div text-center text-white ">
            <h5 className="headings">Best Deals</h5>
          </div>
          <section className="gen-section">
            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <h3>Failed to Fetch</h3>
                </div>
              </div>
            </div>
          </section>
          <div className="headings-div text-center text-white ">
            <h5 className="headings">Recommended For You</h5>
          </div>
          <section className="gen-section">
            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <h3>Failed to Fetch</h3>
                </div>
              </div>
            </div>
          </section>
          <div className="headings-div text-center text-white ">
            <h5 className="headings">Auto Parts</h5>
          </div>
          <section className="gen-section">
            <div className="container text-center">
              <div className="row">
                <div className="col-12">
                  <h3>Failed to Fetch</h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    } else
      return (
        <div className="dealer">
          {/* Best Deals Start */}
          <div
            className="best-deal"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '5px',
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Budget Friendly</h2>
              </div>
              <div className="topnav" style={{display: 'None'}}>
                <Link style={{paddingLeft: '0px'}} className={selectedPriceTab === 0 ? 'active' : ''} onClick={() => this.setState({ selectedPriceTab: 0 })} to="/">under $5,000</Link>
                <Link className={selectedPriceTab === 1 ? 'active' : ''} onClick={() => this.setState({ selectedPriceTab: 1 })} to="/">$5,000 - $15,000</Link>
                <Link style={{paddingRight: '0px'}}className={selectedPriceTab === 2 ? 'active' : ''} onClick={() => this.setState({ selectedPriceTab: 2 })} to="/">$15,000 +</Link>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px'}}>
              <div className="row">
                {dev_landing_data && dev_landing_data.map((d, i) => {
                   return (
                     <LazyLoad height={20} offset={100} once >
                       <PromoElements d={d} i={i}>
                       </PromoElements>
                     </LazyLoad>
                   );
                 })}
              </div>
            </div>
          </div>
          <div style={{display: 'None'}}>
            <LazyLoad height={200} offset={100} once >
              <ShopByMake />
            </LazyLoad>
          </div>

          {/* Banners */}
          <div className="latest-arrival"style={{display: 'None'}}>
            <div className="container mt-4" style={{ height: 'auto', paddingLeft: '-15px' }}>
              <div className="row">
                <LazyLoad height={200}>
                  <div className="promo-banners col-sm-12 col-lg-6 mt-4" style={{ paddingBottom: '5px', paddingLeft: '5px', paddingRight: '5px'}}>
                    <div className="car-accessories">
                      <a href="/partner-one-search-cat/cat-search/0/75000/1/b/parts">
                      <div className="col-lg-12">
                        <h6>New Latest Arrivals</h6>
                        <h2>Auto Parts</h2>
                        <Button className="shop-now" id="shop-now" style={{ fontFamily: 'Mulish'}}>
                          Shop Now
                        </Button>

                      </div>
                      </a>
                    </div>
                  </div>
                </LazyLoad>
                <LazyLoad height={200}>
                  <div className="promo-banners col-sm-12 col-lg-6 mt-4" style={{paddingLeft: '5px', paddingRight: '5px'}}>
                    <div className="electronics">
                      <a href="/partner-one-search-cat/cat-search/0/75000/1/b/phones">
                      <div className="col-lg-12">
                        <h6>The All New 2019</h6>
                        <h2>Electronics</h2>
                        <Button className="shop-now" id="shop-now" style={{ fontFamily: 'Mulish'}}>
                          Shop Now
                        </Button>
                      </div>
                      </a>
                    </div>
                  </div>
                </LazyLoad>
              </div>
            </div>
          </div>
          {/* Banners */}
          <div
            id="cars-tab"
            className="recommended"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingTop: '20px',
              paddingBottom: '1px',
              display: 'None'
            }}>
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Electronics</h2>
              </div>
              <div className="topnav" style={{display: 'None'}}>
                <Link
                  className={selectedSearchedCarTab === 0 ? 'active' : ''}
                  onClick={() => this.setState({ selectedSearchedCarTab: 0 })}
                  to="/"
                >
                  Hatchback
                </Link>
                <Link
                  className={selectedSearchedCarTab === 1 ? 'active' : ''}
                  className={selectedSearchedCarTab === 1 ? 'active' : ''}
                  onClick={() => this.setState({ selectedSearchedCarTab: 1 })}
                  to="/"
                >
                  Sedan
                </Link>
                <Link
                  className={selectedSearchedCarTab === 2 ? 'active' : ''}
                  onClick={() => this.setState({ selectedSearchedCarTab: 2 })}
                  to="/"
                >
                  SUV
                </Link>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '0px' }}>
              <div className="row">
                {homeTechData && homeTechData.map((d, i) => {
                   return (
                     <LazyLoad height={200} offset={100} once >
                       <PromotionUpSellingStatic d={d} i={i}>
                       </PromotionUpSellingStatic>
                     </LazyLoad>
                   );
                 })}
              </div>
            </div>
            <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
            </div>
          </div>
          {/* Recommended For You Ends */}


          {/* commercial vehicles */}
          <div
            className="best-deal"
            id="commercial-vehicles"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingBottom: '10px',
              paddingTop: '10px'
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Commercial Vehicles</h2>
              </div>
            </div>
            <div className="container" style={{ paddingTop: '10px'}}>
              <div className="row">
                {homeCommercialData.map((d, i) => {
                  return (
                     <LazyLoad height={200} offset={100}>
                      <PromoElements d={d} i={i}>
                      </PromoElements>
                     </LazyLoad>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
            </div>
          </div>
          {/*<LocalService />*/}


          {/* Auto Parts Start */}
          <div
            id="auto-parts"
            className="best-deal mt-4"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingBottom: '10px',
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Auto Parts</h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {staticAutoParts.map((d, i) => {
                  return (
                     <LazyLoad height={200} offset={100}>
                      <PromotionUpSellingStatic d={d} i={i}>
                      </PromotionUpSellingStatic>
                     </LazyLoad>
                  );
                })}
              </div>
              <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
              </div>
            </div>
          </div>
          {/* Auto Parts ends */}

          {/* Connect with us starts */}
          <div className="best-deal mt-4" style={{backgroundColor: 'white', height: 'auto', paddingBottom: '10px'}}>
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Lets connect</h2>
              </div>
            </div>
            <div className="container" style={{textAlign: 'center'}}>
              <Row>
                <Col xs={4} sm={4}>
                  <a href="https://www.facebook.com/ChicargosAuto/" target='_blank' rel="noopener noreferrer">
                    <img
                      src={fb}
                      alt="facebook"
                      className="lets-connect-icons"
                    />
                  </a>
                </Col>
                <Col xs={4} sm={4}>
                  <a href="https://www.instagram.com/chicargos_auto/" target='_blank' rel="noopener noreferrer">
                    <img
                      src={insta}
                      alt="Instagram"
                      className="lets-connect-icons"
                    />
                  </a>
                </Col>
                <Col xs={4} sm={4}>
                  <a href="https://twitter.com/ChicargosA" target='_blank' rel="noopener noreferrer">
                    <img
                      src={twitter}
                      alt="Twitter"
                      className="lets-connect-icons"
                    />
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          {/* Connect with us ends */}


          {/* Research & Blog Start */}
          <div
            id="auto-parts"
            className="best-deal mt-4"
            style={{
              backgroundColor: 'white',
              height: 'auto',
              paddingBottom: '10px',
            }}
          >
            <div className="col-lg-6 col-sm-12 offset-lg-3">
              <div className="headings-div-successful" style={{ textAlign: 'center' }}>
                <h2 className="headings">Research & Blog</h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <Col sm={6} className="">
                <ListGroup style={{margin: "15px"}}>
                  <ListGroupItem tag="a" href="/blog/import-a-used-car-to-africa-online/">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 12 Dec, 2019</ListGroupItemHeading>
                    <ListGroupItemText>
                    Guide & Advice: Buying a vehicle online
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-zambia-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>9 Feb, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                    Guide & Advice: Import a car from the UK to ZAMBIA
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-zimbabwe-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>6 May, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                    Guide & Advice: Import a car from the UK to ZIMBABWE
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-malawi-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>6 May, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                    Guide & Advice: Import a car from the UK to MALAWI
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-eswatini-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>12 May, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Import used car from the UK to SWAZILAND
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-lesotho-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>17 May, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Import used car from the UK to LESOTHO
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-kenya-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}>24 June, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Import used car from the UK to KENYA
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/zambia/zra-vehicle-duty-calculator">
                    <ListGroupItemHeading style={{fontWeight: 700}}>02 Oct, 2022</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Zambia - ZRA Duty calculator
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>


                </Col>
                <Col sm={6} className="">
                <ListGroup style={{margin: "15px"}}>
                  <ListGroupItem tag="a" href="/blog/chicargos-experience/">
                    <ListGroupItemHeading style={{fontWeight: 700}}>11 Nov, 2019</ListGroupItemHeading>
                      <ListGroupItemText>
                        Blog: The chicargos.com experience
                      </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-botswana-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 5 May, 2020</ListGroupItemHeading>
                      <ListGroupItemText>
                        Guide & Advice: Import a car from UK to BOTSWANA
                      </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-mauritius-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 7 May, 2020</ListGroupItemHeading>
                      <ListGroupItemText>
                        Guide & Advice: Import a car from UK to MAURITIUS
                      </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-namibia-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 10 May, 2020</ListGroupItemHeading>
                      <ListGroupItemText>
                        Guide & Advice: Import a car from UK to NAMIBIA
                      </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-mozambique-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 13 May, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Import a car from UK to MOZAMBIQUE
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/import-car-to-tanzania-from-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 22 June, 2020</ListGroupItemHeading>
                    <ListGroupItemText>
                      Guide & Advice: Import a car from UK to Tanzania
                    </ListGroupItemText>
                  </ListGroupItem>
                  <ListGroupItem tag="a" href="/article/why-import-from-the-uk">
                    <ListGroupItemHeading style={{fontWeight: 700}}> 22 Sept, 2022</ListGroupItemHeading>
                    <ListGroupItemText>
                      Article: Why import an ex UK vehicle?
                    </ListGroupItemText>
                  </ListGroupItem>
                </ListGroup>
                </Col>
              </div>

              <div className="col-lg-3 col-lg-3 col-sm-6 col-xs-6 ">
              </div>
            </div>
          </div>
          {/* Research & Blog */}

        </div>
      );
  }
}

export default withRouter(BestDeals);
