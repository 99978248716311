export const charges = [
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "29,947.10",
    "vehicle_type": "Sedan ",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "29,947.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "31,872.96",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "28,199.95",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "32,305.02",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "43,429.10",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "52,673.90",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "60,763.10",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "29,947.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Sedan",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "29,947.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Hatchback",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "22,243.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "26,095.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "31,872.96",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "36,688.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "41,503.10",
    "vehicle_type": "Station Wagons",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "28,199.95",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "32,305.02",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "43,429.10",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "52,673.90",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "60,763.10",
    "vehicle_type": "SUVs",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "55,948.10",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "61,244.60",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1 tonne",
    "duty": "19,739.31",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043100",
    "description": "2 tonnes",
    "duty": "22,965.35",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043100",
    "description": "5 tonnes",
    "duty": "27,058.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043200",
    "description": "10 tonnes",
    "duty": "31,873.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043200",
    "description": "20 tonnes",
    "duty": "39,577.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043200",
    "description": "21 tonnes",
    "duty": "46,318.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87044100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "55,948.10",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "61,244.60",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "1 tonne",
    "duty": "19,739.31",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "2 tonnes",
    "duty": "22,965.35",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "27,058.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044200",
    "description": "10 tonnes",
    "duty": "31,873.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044200",
    "description": "20 tonnes",
    "duty": "39,577.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044300",
    "description": "21 tonnes",
    "duty": "46,318.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Single Cab",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "55,948.10",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "61,244.60",
    "vehicle_type": "Double Cab",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1 tonne",
    "duty": "19,739.31",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1.5 tonnes",
    "duty": "22,243.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "36,688.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "41,503.10",
    "vehicle_type": "Panel Van",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "2 tonnes",
    "duty": "22,965.35",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "27,058.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "10 tonnes",
    "duty": "31,873.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "20 tonnes",
    "duty": "39,577.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "21 tonnes",
    "duty": "46,318.10",
    "vehicle_type": "Truck",
    "age": ">5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87021010",
    "description": "14 seater",
    "duty": "31,873.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "32 seater",
    "duty": "33,799.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "44 seater",
    "duty": "46,318.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "44+ seater",
    "duty": "99,283.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87029010",
    "description": "14 seater",
    "duty": "31,873.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "32 seater",
    "duty": "33,799.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "44 seater",
    "duty": "46,318.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "44+ seater",
    "duty": "99,283.10",
    "vehicle_type": "Bus",
    "age": ">5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87021010",
    "description": "14 seater",
    "duty": "58,763.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "32 seater",
    "duty": "87,653.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "44 seater",
    "duty": "193,583.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87021090",
    "description": "44+ seater",
    "duty": "241,733.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87029010",
    "description": "14 seater",
    "duty": "58,763.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "32 seater",
    "duty": "87,653.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "44 seater",
    "duty": "193,583.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87029090",
    "description": "44+ seater",
    "duty": "241,733.10",
    "vehicle_type": "Bus",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "34,688.10",
    "vehicle_type": "Sedan",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "44,318.10",
    "vehicle_type": "Sedan",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "53,948.10",
    "vehicle_type": "Sedan",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "58,763.10",
    "vehicle_type": "Sedan",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "73,208.10",
    "vehicle_type": "Sedan",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "29,873.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "39,503.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "49,133.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "53,948.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "63,578.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "34,688.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "44,315.67",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "53,948.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "58,763.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "73,208.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032190",
    "description": "1000cc",
    "duty": "43,011.18",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032290",
    "description": "1500cc",
    "duty": "49,852.96",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "2500cc",
    "duty": "68,393.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032390",
    "description": "3000cc",
    "duty": "78,023.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87032490",
    "description": "3001cc",
    "duty": "92,468.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "34,688.10",
    "vehicle_type": "Sedans",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "44,318.10",
    "vehicle_type": "Sedans",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "53,948.10",
    "vehicle_type": "Sedans",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "58,763.10",
    "vehicle_type": "Sedans",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "73,208.10",
    "vehicle_type": "Sedans",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "29,873.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "39,503.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "49,133.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "53,948.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "68,393.10",
    "vehicle_type": "Hatchback",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "34,688.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "44,315.67",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "53,948.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "3000cc",
    "duty": "58,763.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "3001cc",
    "duty": "73,208.10",
    "vehicle_type": "Station Wagons",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1000cc",
    "duty": "43,011.18",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033190",
    "description": "1500cc",
    "duty": "49,852.96",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033290",
    "description": "2500cc",
    "duty": "68,393.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3000cc",
    "duty": "78,023.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87033390",
    "description": "3001cc",
    "duty": "92,468.10",
    "vehicle_type": "SUVs",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1.5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "58,763.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "75,134.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1 tonne",
    "duty": "30,306.46",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "1.5 tonnes",
    "duty": "34,688.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "3 tonnes",
    "duty": "39,503.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "2 tonnes",
    "duty": "44,318.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042200",
    "description": "10 tonnes",
    "duty": "78,023.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042200",
    "description": "20 tonnes",
    "duty": "97,283.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87042300",
    "description": "21 tonnes",
    "duty": "116,543.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel"
  },
  {
    "hs_code": "87043100",
    "description": "1.5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "3 tonnes",
    "duty": "58,763.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "5 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "3 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "5 tonnes",
    "duty": "75,134.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "1 tonne",
    "duty": "30,306.46",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "1.5 tonnes",
    "duty": "34,688.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "3 tonnes",
    "duty": "39,503.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "2 tonnes",
    "duty": "44,318.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043200",
    "description": "10 tonnes",
    "duty": "78,023.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043200",
    "description": "20 tonnes",
    "duty": "97,283.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87043200",
    "description": "21 tonnes",
    "duty": "116,543.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol"
  },
  {
    "hs_code": "87044100",
    "description": "1.5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "58,763.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "75,134.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "1 tonne",
    "duty": "30,306.46",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "1.5 tonnes",
    "duty": "34,688.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "3 tonnes",
    "duty": "39,503.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "2 tonnes",
    "duty": "44,318.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044200",
    "description": "10 tonnes",
    "duty": "78,023.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044200",
    "description": "20 tonnes",
    "duty": "97,283.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87044300",
    "description": "21 tonnes",
    "duty": "116,543.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Diesel/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1.5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "58,763.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Single Cab",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "68,393.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "75,134.10",
    "vehicle_type": "Double Cab",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1 tonne",
    "duty": "30,306.46",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "1.5 tonnes",
    "duty": "34,688.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "3 tonnes",
    "duty": "39,503.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Panel Van",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "2 tonnes",
    "duty": "44,318.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045100",
    "description": "5 tonnes",
    "duty": "49,133.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "10 tonnes",
    "duty": "78,023.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "20 tonnes",
    "duty": "97,283.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  },
  {
    "hs_code": "87045200",
    "description": "21 tonnes",
    "duty": "116,543.10",
    "vehicle_type": "Truck",
    "age": "2-5",
    "fuel_type": "Petrol/Electric"
  }
]