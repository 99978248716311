import React, {Component} from 'react';
import Tab from 'react-bootstrap/Tab';
import {Helmet} from "react-helmet";

import {
  Col,
  Row
} from "reactstrap";

class AboutUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>CHICARGOS - About Us</title>
          <link rel="canonical" href="https://www.exukonline.com/about-us" />
          <meta name="description"
                content="UK Premier Import Service - A Global e-commerce company" />
        </Helmet>
        <section className="gen-section  filter_space">
          <div className="container mobile_padding">
            <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="static-info-page">
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="page-header-content" style={{ color: '#b60811'}}>Who we are</h4>
                  <hr className="fancy-line" />
                  <div className="col-lg-6 col-sm-12 offset-lg-3">
                   <div className="help-department-heading stock-procurement-heading">
                   </div>
                  </div>
                  <br />
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} className="">
                        <div style={{padding: "30px", paddingTop: "5px"}}>
                          <p className="how-to-steps" style={{fontSize: '14px'}}>About Us</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}> Established in November 2018, CHICARGOS is the premier exporter of commercial,
                              luxury and performance vehicles from UK. We assist our customers with their stock
                              procurement requirements with ease and convenience. As the leading motor vehicle
                              exporter in the UK, we help our customers to export a wide range of new and pre-owned
                              vehicles of popular car brands such as BMW, Audi, Mercedes Benz, Jaguar, Lexus and
                              Range Rover among others.</p>
                            <p className="page-information" style={{fontSize: '14px'}}> We are also a highly trusted provider of electronics and auto parts in the country.
                              Our highly professional and expert team helps you to procure a wide variety of
                              automobile parts at highly affordable rates.</p>

                          <p className="how-to-steps" style={{fontSize: '14px'}}>Our Approach</p>
                            <p className="page-information" style={{fontSize: '14px', paddingTop: '0px'}}>  At CHICARGOS, we are committed to providing our customers with best quality,
                              prompt and efficient services. We export a range of vehicles to match their needs and
                              facilitate their exporting requirements. All our vehicles sourced as well as procured
                              by our company are of the highest quality and make. They are designed to last long and
                              are fully approved by experienced engineers.</p>
                          <p className="how-to-steps" style={{fontSize: '14px'}}>Why Us?</p>
                            <ul className="page-information" style={{textAlign: "left", fontSize: '14px', paddingTop: '0px'}} >
                              <li > We are a fully insured and licensed exporter of motor vehicles from UK. </li>
                              <li> We emphasize on offering enhanced customer experience and a quick, as well as easy buying
                              process. Our large selection of diesel and petrol vehicles allows customers to pick their
                                vehicles wisely.</li>
                              <li> Our goal is to assist our clients through delivery of superior motor vehicles that further
                                enables them to achieve their business objectives.</li>
                            </ul>
                          <p className="page-information" style={{fontSize: '14px'}}> Over the years, we have worked hard to build a strong reputation among our clients in the
                            UK by providing them with 100% prompt and friendly services.</p>
                          <p className="info-graphics-heading" style={{fontSize: '14px'}} > Connect with us to discuss your requirements!</p>
                          <p className="info-graphics-heading" style={{fontSize: '14px', color: '#980000'}} > Call us on +44 7438 767532</p>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Container>
                  <hr />
                </div>
              </div>
           </div>
           </div>
          </div>
        </section>
      </div>
    )
  }

}

export default AboutUs;
